<script setup>
import { useRoute } from 'vue-router'
import { useCookie } from 'nuxt/app'

// TODO Add the basic SEO information for each page: Title, Meta Description
// ZigZag required scripts and styles
const config = useRuntimeConfig()
const {version} = useZigZag()
const {data, refresh, error} = await version(config.public.zigzagUrl)
const time = data.value ? data.value.t : 0;
const sha = data.value ? data.value.sha : 'versionnotfound';
const route = useRoute();

onMounted(() => {
  useHead({      
    link: [    
      {
        rel: 'stylesheet',
        href: `${config.public.zigzagUrl}/styles/pulseapp.css?sha=${sha}&t=${time}`
      }
    ],
    script: [
      {
        src: `${config.public.zigzagUrl}/${config.public.zigzag3rdParty}?sha=${sha}&t=${time}`,
        body: true
      }
    ],
  });
})

// initial values for properties on navBar and adding a key to force vue to rerender the navbar when it's required
const topNavColor = ref('light'), toggleHeroHeader = ref(true), key = ref('navbar-default')

function changeNavBarOptions(heroHeader) {
    if (heroHeader.toggleHeroHeader) {
    topNavColor.value = heroHeader.topNavColor;
    toggleHeroHeader.value = heroHeader.toggleHeroHeader;
    // Whenever the heroHeader has some value we change the key and force vue to rerender the navbar
    key.value = 'navbar-overrided';
  }
  else {
    // In case the heroHeader doesn't exists we set the default values and force the rerender again
    topNavColor.value = 'dark';
    toggleHeroHeader.value = false;
    key.value = 'navbar-default';
  }
}

const mainClasses = computed(() => {
  return {
    'no-hero': route.params.navbar === 'default'
  }
})

// Update UTM cookies with parameters if they exist in the URL
for (let param in route.query) {
  let options = {
    maxAge: 864000 // 10 days to expire
  }
  let cookieVal = useCookie(param, options)
  cookieVal.value = route.query[param]
}
</script>
<template>
  <PromotionsBanner v-if="route.params.showPromoBanner" />  
  <LayoutNavBar :navColor="route.params.navColor" :hero="route.params.toggleHeroHeader" :promoBanner="route.params.showPromoBanner" :topNavMenu="route.params.showTopNavMenu"/>
  <main :class="mainClasses">
    <slot/>
  </main>
  <LayoutFooterNavigation :footerMenu="route.params.showFooterMenu"/>
  <LayoutCookieWarning v-if="route.params.cookieBanner"/>
</template>